import React, { useEffect } from "react";
import { NavBar } from "../components/navigation/NavBar";
import { PageFooter } from "./footer/PageFooter";
import { ContactUsModal } from "./ui/ContactUsModal";
import { Cookie } from "./ui/Cookie";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";

export const PageLayout = ({ children, customClass }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const location = useLocation();
  const currentURL = location.pathname;
  return (
    <div style={{ maxHeight: "100vh" }}>
      <NavBar />
      <div
        className={`${
          customClass ? customClass : ""
        } pt-14 xl:pt-20  2xl:pt-24`}
      >
        {children}
      </div>
      <ContactUsModal />
      {currentURL !== "/calculator" && (
        <NavLink
          to="/calculator"
          className="fixed left-0 top-1/2 z-10 flex flex-col items-center justify-center rounded-md bg-secondary-100 bg-opacity-80 px-1 py-2 text-xl font-medium text-white transition duration-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 lg:px-2 lg:py-3 lg:text-3xl"
        >
          <FontAwesomeIcon className="mb-2 font-bold" icon={faCalculator} />
          <span className="text-xs">Calculator</span>
        </NavLink>
      )}
      <Cookie />
      <PageFooter />
    </div>
  );
};
