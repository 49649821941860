import React, { useState } from "react";

export const FormInput = (props) => {
  const [focused, setFocused] = useState(false);
  const { label, errorMessage, onChange } = props;

  const handleFocus = (event) => {
    if (event.target.value) {
      setFocused(true);
    }
  };

  return (
    <div className="group relative z-0 mb-2 w-full ">
      <input
        type={props.type}
        name={props.name}
        onChange={onChange}
        onBlur={handleFocus}
        focused={focused.toString()}
        id={`floating-${props.name}`}
        pattern={props.pattern}
        className="xl:text-md peer block w-full appearance-none border-0  border-b-2 border-gray-300 bg-transparent px-0   py-1 pt-7 text-sm text-gray-900 focus:border-secondary-100 focus:outline-none focus:ring-0 "
        placeholder=" "
        required={props.required}
      />
      <label
        htmlFor={props.name}
        className="peer-focus:secondary-100 absolute top-5 -z-10 origin-[0] -translate-y-3 scale-75 transform text-sm font-bold text-main-100 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75  "
      >
        {label}
      </label>
      <span className="input_error absolute  right-0">{errorMessage}</span>
    </div>
  );
};
