import React from "react";

export default function Catalogue({ src }) {
  return (
    <div className="">
      <iframe
        allowFullScreen="allowfullscreen"
        title="catalogue"
        className="fp-iframe h-[720px] w-full"
        src={src}
      ></iframe>
    </div>
  );
}
