import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
export const NavBarLink = ({ path, label, submenu }) => {
  return (
    <li className="parent-nav relative ">
      <NavLink
        to={path}
        end
        className={({ isActive }) =>
          `block truncate  py-2 pb-2 text-center font-main text-xl font-bold uppercase duration-100 ease-in last:mr-0  hover:text-secondary-100  md:py-4 md:pb-2 md:text-3xl  2xl:text-right ${
            !isActive ? `text-gray-700` : `cursor-auto text-secondary-100`
          }`
        }
      >
        {path === "/" && <FontAwesomeIcon icon={faHome} className="mr-2" />}
        {label}
      </NavLink>

      {submenu && (
        <ul
          className="child-nav flex-col transition-all duration-500"
          aria-labelledby="dropdownonHovernavLink"
        >
          {submenu.map((subLink, i) => (
            <li key={i}>
              <NavLink
                className={({ isActive }) =>
                  `text-md block py-1 text-center font-main font-semibold duration-100 ease-in  hover:text-secondary-100 md:text-xl 2xl:text-right ${
                    !isActive
                      ? `text-gray-700`
                      : `cursor-auto text-secondary-100`
                  }`
                }
                to={subLink.path}
              >
                {subLink.label}
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};
