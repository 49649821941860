import React, { useState } from "react";
import { FormInput } from "./FormInput";
import emailjs from "@emailjs/browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

export const ContactUsForm = (props) => {
  const [error, setError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const inputs = [
    {
      id: 1,
      name: "name",
      type: "text",
      errorMessage: "No special character or numbers",
      label: "Name",
      pattern: "^[a-zA-Z\\s]*$",
      required: true,
    },
    {
      id: 2,
      name: "email",
      type: "email",
      errorMessage: "Should be a valid email address!",
      label: "Email",
      pattern: "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}$",
      required: true,
    },
    {
      id: 3,
      name: "phone",
      type: "tel",
      errorMessage: "Invalid Phone Number XXXXXXXXXX",
      pattern: "^\\d{10}$",
      label: "Phone",
      required: true,
    },
  ];
  const sendEmail = (e) => {
    e.preventDefault();
    props.setLoading(true);
    emailjs
      .send("service_xbo6uj8", "template_3ajry0e", values, "2F5RHCwWHpwtJRazc")
      .then(
        (result) => {
          props.setLoading(false);
          setFormSubmitted(true);
          setError(false);
          setTimeout(() => {
            closeModal();
          }, 3000);
        },
        (error) => {
          props.setLoading(false);
          setError(true);
        }
      );
  };

  const closeModal = () => props.closeModal(false);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  return (
    <form
      className={(props.loading && "opacity-30 ") + "relative flex flex-col"}
      onSubmit={sendEmail}
    >
      <div className="mb-2">
        {!formSubmitted &&
          inputs.map((input) => (
            <FormInput
              key={input.id}
              {...input}
              value={values[input.name]}
              onChange={onChange}
            />
          ))}
        {!formSubmitted && (
          <div className="group relative z-0 mb-5 w-full  xl:mb-10 ">
            <textarea
              type="text"
              name="message"
              onChange={onChange}
              maxLength={250}
              rows="4"
              className="peer block max-h-24 w-full appearance-none border-0 border-b-2 border-gray-300  bg-transparent px-0 py-1 pt-7  text-sm text-gray-900 focus:border-secondary-100 focus:outline-none focus:ring-0 xl:max-h-32"
              placeholder=" "
            ></textarea>
            <label
              htmlFor="message"
              className="peer-focus:secondary-100 absolute top-6 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm font-bold text-main-100 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75"
            >
              Message
            </label>
          </div>
        )}
        {formSubmitted && !error && (
          <p className="mt-4 text-center text-sm font-medium text-secondary-100">
            <FontAwesomeIcon icon={faCheckCircle} className=" mr-2 font-bold" />
            Form has been successfully submitted. Thank you!
          </p>
        )}
        {error && (
          <p className="mt-4 text-center text-sm font-medium text-red-500">
            <FontAwesomeIcon icon={faCircleXmark} className=" mr-2 font-bold" />
            Error: Something went wrong. Please try again later. If the issue
            persist, please contact customer service.
          </p>
        )}
        <div className="mt-12 flex">
          {!error && !formSubmitted && (
            <button
              type="submit"
              className="w-full rounded-lg  bg-secondary-100 px-6 py-2 text-center text-sm font-medium uppercase text-white transition-colors duration-300 hover:bg-gray-400 focus:outline-none focus:ring-4 focus:ring-blue-300 "
            >
              Submit
            </button>
          )}
          <button
            onClick={closeModal}
            type="button"
            className="ml-4 w-full rounded-lg bg-gray-300 px-6 py-2 text-center text-sm font-medium uppercase text-white transition-colors duration-300 hover:bg-gray-600 focus:outline-none   "
          >
            Close
          </button>
        </div>
      </div>
    </form>
  );
};
