import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";
import "swiper/swiper-bundle.css";

import whiteLogo from "../../assets/white_logo_resized.png";
import { getAllFiles } from "../../services/file.services";
import { RotatingLines } from "react-loader-spinner";

const breakpoints = {
  320: {
    slidesPerView: 1,
  },
};
export const HomeCarousel = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    const fetchFeedbacks = async () => {
      try {
        const { data } = await getAllFiles();
        if (isMounted && data) setImages(data);
      } catch (error) {
        console.error("Error fetching files:", error);
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    fetchFeedbacks();
    return () => (isMounted = false);
  }, []);

  return (
    <div className="relative">
      {loading && (
        <div className="image-carousel skeleton-loader  z-20 flex items-center justify-center bg-gray-900 bg-opacity-30">
          <RotatingLines width="50" />
        </div>
      )}
      <div className="container absolute left-0 right-0 top-6 z-10 mx-auto w-full px-6  md:top-8 lg:top-12 ">
        <img
          src={whiteLogo}
          alt=""
          className=" w-20 sm:w-32 md:w-36  xl:w-40 2xl:w-2/12"
          loading={"lazy"}
        />
      </div>
      <Swiper
        breakpoints={breakpoints}
        loop={true}
        effect={"fade"}
        navigation={false}
        modules={[Autoplay, EffectFade]}
        autoplay={{
          delay: 12000,
          disableOnInteraction: false,
        }}
      >
        {images.map((img) => (
          <SwiperSlide key={img.id}>
            <div
              key={img.id}
              className="image-carousel relative bg-cover bg-center bg-no-repeat"
              style={{
                backgroundImage: `url("${img.fileUrl}")`,
              }}
            >
              <div className="container absolute bottom-10 left-0 right-0 z-10 mx-auto w-full px-6 xl:bottom-16  2xl:bottom-20  ">
                <h1 className=" text-md w-full text-left font-normal text-white sm:text-2xl sm:tracking-wider lg:text-3xl xl:w-8/12 xl:text-4xl 2xl:text-5xl">
                  {img.bannerText}
                </h1>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
