import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { PageLoader } from "./page-loader";

export const AuthenticationGuard = ({ component: Component, ...props }) => {
  const ProtectedComponent = withAuthenticationRequired(Component, {
    onRedirecting: () => <PageLoader />, // Ensure this returns JSX
  });

  return <ProtectedComponent {...props} />;
};
