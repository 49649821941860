import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/admin",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  return (
    <button
      className="block truncate py-2 pb-2  font-main text-xl font-bold uppercase text-gray-700  duration-100  ease-in last:mr-0 hover:text-secondary-100  md:py-4 md:pb-2
             md:text-3xl
          2xl:text-right"
      onClick={handleLogin}
    >
      Login
    </button>
  );
};
