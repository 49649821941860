import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
export const Cookie = () => {
  const [cookieWindowVisible, setCookieWindowVisible] = useState(false);

  useEffect(() => {
    const cookie = localStorage.getItem("abiCookie");
    const timeoutId = setTimeout(() => {
      if (cookie) setCookieWindowVisible(false);
      else setCookieWindowVisible(true);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [cookieWindowVisible]);

  const acceptCookie = () => {
    localStorage.setItem("abiCookie", true);
    setCookieWindowVisible(false);
  };

  return (
    <div
      className={`fixed left-0 right-0 z-10 bg-white  drop-shadow transition-all duration-300 ${
        cookieWindowVisible ? "bottom-0" : " -bottom-full"
      }`}
    >
      <div className="container mx-auto ">
        <div className="m-6 grid grid-cols-8 gap-4 text-sm sm:text-base lg:gap-10">
          <p className=" col-span-8 text-center text-main-100 lg:col-span-6 lg:text-left">
            We use cookies to enable website functionality, understand the
            performance of our site, provide social media features, and serve
            more content relevant to you. You may review our{" "}
            <NavLink
              to="/privacy"
              className="text-red-600 transition duration-300 hover:text-secondary-100 focus:text-secondary-100 "
            >
              Privacy Policy{" "}
            </NavLink>
            and our{" "}
            <NavLink
              to="/terms"
              className="text-red-600 transition duration-300 hover:text-secondary-100 focus:text-secondary-100"
            >
              Terms of Use Policy
            </NavLink>
            .
          </p>
          <div className=" col-span-8 self-center justify-self-center lg:col-span-2">
            <button
              onClick={acceptCookie}
              className="border bg-main-100 px-6 py-2 text-white transition duration-300 hover:bg-white hover:text-main-100 focus:bg-white focus:text-main-100"
            >
              Accept Cookies
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
