import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Navigation } from "swiper";
import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { getAllFeedbacks } from "../../services/feedback.service";

const breakpoints = {
  300: {
    slidesPerView: 1,
  },

  640: {
    slidesPerView: 2,
  },
  1280: {
    slidesPerView: 3,
  },
};
export default function FeedbacksCarousel() {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    const fetchFeedbacks = async () => {
      try {
        const { data } = await getAllFeedbacks();
        if (isMounted && data) setFeedbacks(data);
      } catch (error) {
        console.error("Error fetching files:", error);
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    fetchFeedbacks();
    return () => (isMounted = false);
  }, []);

  return (
    <Swiper
      breakpoints={breakpoints}
      loop={true}
      navigation={true}
      modules={[Navigation]}
      className="reviews-container "
    >
      {loading && (
        <div className="absolute inset-0 z-20 flex items-center justify-center bg-gray-900 bg-opacity-30">
          <RotatingLines width="50" />
        </div>
      )}
      {feedbacks.map((rev) => (
        <SwiperSlide key={rev.id} className=" px-10 py-10 lg:py-14">
          <div className="feedback-item relative  p-6 text-center font-normal text-white  ">
            <p className="text-md mb-8 md:text-lg ">{rev.feedbackText}</p>
            <span className="md:text-md block text-sm">{rev.personName}</span>
            <span className="md:text-md block text-sm font-medium uppercase">
              {rev.companyName}
            </span>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
