import React from "react";

export const PageLoader = () => {
  const loadingImg = "https://cdn.auth0.com/blog/hello-auth0/loader.svg";

  return (
    <div className="flex h-screen items-center justify-center bg-gray-100">
      <img className="max-h-64 w-auto" src={loadingImg} alt="Loading..." />
    </div>
  );
};
