import React, { useState } from "react";
import { NavBarBrand } from "./NavBarBrand";
import { NavMenuButton } from "./NavMenuButton";
import { NavMenu } from "./NavMenu";

export const NavBar = () => {
  const [navOpen, setNavOpen] = useState(false);
  const toggleMenu = () => setNavOpen(!navOpen);
  return (
    <div
      className={
        (navOpen ? "navOpen" : "") +
        " navigation fixed z-20  h-auto w-full bg-white   py-4 shadow-md xl:py-6 2xl:py-8 "
      }
    >
      <div className="container relative mx-auto">
        <div className="px-6">
          <NavBarBrand customStyle="text-main-100">ABI MOULDINGS</NavBarBrand>
          <NavMenuButton toggleMenu={toggleMenu} navOpen={navOpen} />

          <NavMenu setNavOpen={setNavOpen} />
        </div>
      </div>
    </div>
  );
};
