import React from "react";
import { NavBarLinks } from "./NavBarLinks";
import {
  faFacebook,
  faLinkedin,
  faSquareInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const NavMenu = ({ setNavOpen }) => {
  return (
    <div className="menu  w-full">
      <div className="container relative mx-auto h-full w-full">
        <FontAwesomeIcon
          icon={faCircleXmark}
          className="absolute right-4 top-3 cursor-pointer  p-3 text-4xl text-main-100 transition-all duration-300 hover:text-secondary-100"
          onClick={() => setNavOpen(false)}
        />
        <ul className="absolute  left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col justify-center rounded-lg font-medium transition-all duration-300  lg:p-0 2xl:left-1/2 2xl:right-8 2xl:top-1/2 2xl:-translate-x-0 2xl:-translate-y-1/2 2xl:transform">
          <NavBarLinks />
          <li className="z-10  py-10">
            <div className="flex justify-center  text-3xl md:text-4xl 2xl:justify-end ">
              <a
                href="https://www.instagram.com/abimouldings/"
                target="_blank"
                rel="noopener noreferrer"
                className=" text-main-100 transition-all duration-300 hover:text-secondary-100"
              >
                <FontAwesomeIcon icon={faSquareInstagram} />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100087795861391"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-3  text-main-100  transition-all duration-300 hover:text-secondary-100"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                href="https://www.linkedin.com/company/abi-mouldings-ltd/"
                target="_blank"
                rel="noopener noreferrer"
                className=" text-main-100  transition-all duration-300 hover:text-secondary-100"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
