import React from "react";
import { NavLink } from "react-router-dom";

export const NavBarBrand = ({ children, customStyle }) => {
  return (
    <NavLink
      to="/"
      className={`${customStyle} cursor-pointer text-xl font-bold uppercase text-gray-700  md:text-2xl   2xl:text-3xl`}
    >
      {children}
    </NavLink>
  );
};
