import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const LogoutButton = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <button
      className="block truncate  py-2 pb-2 text-center font-main text-xl font-bold uppercase duration-100 ease-in last:mr-0  hover:text-secondary-100  md:py-4 md:pb-2 md:text-3xl  2xl:text-right"
      onClick={handleLogout}
    >
      Log Out
    </button>
  );
};
