import { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContactUsForm } from "./Form";
import { InfinitySpin } from "react-loader-spinner";
export const ContactUsModal = () => {
  const [loading, setLoading] = useState(false);
  let [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <button
        type="button"
        onClick={openModal}
        className="fixed bottom-12 right-6 z-10  flex flex-col items-center justify-center rounded-md bg-secondary-100 bg-opacity-80 px-1 py-2 text-xl font-medium text-white transition duration-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 lg:right-12 lg:px-2 lg:py-3 lg:text-3xl"
      >
        <FontAwesomeIcon icon={faMessage} className="mb-1 font-bold" />
        <span className="text-xs">Contact Us</span>
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0  bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed top-14 overflow-y-auto sm:bottom-12  sm:right-12 sm:top-auto">
            <div className="flex   w-screen items-center justify-center  p-4 text-center sm:w-96 ">
              <Transition.Child
                as={Fragment}
                enter="transform transition-opacity ease-out duration-300 "
                enterFrom="opacity-0 scale-95 "
                enterTo="opacity-100 scale-100"
                leave="transform transition-opacity ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6  text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h2"
                    className="mb-4 text-center text-xl font-medium leading-6 "
                  >
                    Contact Us
                  </Dialog.Title>
                  <ContactUsForm
                    closeModal={setIsOpen}
                    setLoading={setLoading}
                    loading={loading}
                  />
                  {loading && (
                    <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
                      <InfinitySpin width="200" color="#4fa94d" />
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
