import { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export const ModalCategory = (props) => {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  const { icon, header, paragraph, path } = props.values;
  return (
    <div>
      <button className="group self-center text-left" onClick={openModal}>
        <img
          src={icon}
          alt="Icon"
          className="mx-auto mb-2 h-20 w-20 sm:mx-0 sm:h-24 sm:w-24 lg:mb-3 lg:h-28 lg:w-28 2xl:mb-4 2xl:h-32 2xl:w-32"
        />
        <h4 className="text-md mb-2 text-center font-bold tracking-wide transition duration-300 group-hover:text-secondary-100 sm:text-left sm:text-lg  lg:mb-3 lg:text-xl 2xl:mb-4  2xl:text-2xl">
          {header}
        </h4>
        <p className="2xl:text-md text-center text-xs sm:text-left lg:text-sm">
          {paragraph}
        </p>
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 flex items-center justify-center"
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transform transition-opacity ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transform transition-opacity ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="w-full max-w-md rounded-2xl bg-white p-6 shadow-xl">
              <Dialog.Title
                as="h3"
                className="mb-4 text-center text-2xl font-medium leading-6"
              >
                {path === "/custom" ? "Custom Orders" : "Delivery"}
              </Dialog.Title>

              {path === "/custom" ? (
                <>
                  <p>
                    At ABI, we take immense pride in offering not only
                    top-quality MDF mouldings and doors but also the opportunity
                    to bring your unique visions to life through our custom
                    manufacturing services. Our skilled staff are ready to turn
                    your ideas into reality with precision and finesse. Let's
                    embark on a creative journey together and turn your ideas
                    into timeless masterpieces!
                  </p>
                </>
              ) : (
                <p>
                  Simply place your order, and our dedicated team will handle
                  the logistics. Your MDF mouldings and doors will be delivered
                  promptly and securely to your business address. Thank you for
                  choosing us as your trusted supplier. We are excited to serve
                  you with our reliable delivery services and exceptional
                  products!
                </p>
              )}
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </div>
  );
};
