import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getAllFeedbacks = async () => {
  const config = {
    url: `${apiServerUrl}/feedback`,
    method: "GET",

    headers: {
      "content-type": "application/json",
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data.data || null,
    error,
  };
};

export const createFeedback = async (accessToken, formData) => {
  const config = {
    method: "post",
    url: `${apiServerUrl}/feedback`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  };

  return await callExternalApi({ config });
};

export const deleteFeedback = async (accessToken, feedbackId) => {
  const config = {
    method: "delete",
    url: `${apiServerUrl}/feedback/${feedbackId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return await callExternalApi({ config });
};

export const updateFeedback = async (accessToken, feedbackId, formData) => {
  const config = {
    method: "patch",
    url: `${apiServerUrl}/feedback/${feedbackId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  };
  return await callExternalApi({ config });
};
