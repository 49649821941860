import { NavLink } from "react-router-dom";

export const Category = (props) => {
  const { icon, header, paragraph, path } = props.values;
  return (
    <NavLink to={path} className="group self-center">
      <img
        src={icon}
        alt="Icon"
        className="image-class mx-auto mb-2 h-20 w-20 sm:mx-0 sm:h-24 sm:w-24 lg:mb-3 lg:h-28 lg:w-28 2xl:mb-4 2xl:h-32 2xl:w-32"
        loading={"lazy"}
      />
      <h3 className="text-md mb-2 text-center font-bold tracking-wide transition duration-300 group-hover:text-secondary-100 sm:text-left sm:text-lg  lg:mb-3 lg:text-xl 2xl:mb-4  2xl:text-2xl">
        {header}
      </h3>
      <p className="2xl:text-md text-center text-xs sm:text-left lg:text-sm">
        {paragraph}
      </p>
    </NavLink>
  );
};
