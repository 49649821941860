import React from "react";
import {
  faFacebook,
  faLinkedin,
  faSquareInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

export const PageFooter = () => {
  const handleScroll = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <footer className="">
      <div className="container mx-auto">
        <div className=" flex flex-col items-center justify-center  px-6 py-12 font-medium  md:flex-row md:items-start md:justify-between  ">
          <div className="mb-8 text-center   md:mb-0 md:text-start ">
            <a
              href="https://www.google.com/maps/place/ABI+Mouldings+Ltd./@51.0025947,-113.9941746,17z/data=!3m1!4b1!4m6!3m5!1s0x53717a6f9b7bec93:0x520aa7504efe8859!8m2!3d51.0025947!4d-113.9915997!16s%2Fg%2F1tk6_514"
              title="Our office is located at 3131 57 Avenue SE, Calgary, Alberta T2C 0B2"
              target="_blank"
              rel="noopener noreferrer"
              className="transition-all duration-300 hover:text-main-200"
            >
              <h3 className="mb-2 text-lg font-semibold sm:text-xl  ">
                Contact Us
              </h3>
              <span>
                3131 57 Avenue SE Calgary
                <br />
                AB, T2C 0B2
                <br />
                Canada
              </span>
              <span className="mt-2 block underline">Get directions</span>
            </a>
          </div>
          <div className="  mb-8 text-center md:mb-0  md:text-left ">
            <h3 className="mb-2 text-lg font-semibold sm:text-xl  ">Office</h3>
            <span>
              Monday-Friday
              <br />
              07:00 a.m. - 04:00 p.m.
              <br />
              Saturday-Sunday
              <br />
              Closed
            </span>
          </div>
          <div className=" mb-8  text-center  md:mb-0 md:text-left">
            <h3 className="mb-2 text-lg font-semibold sm:text-xl  ">
              Shipping and Receiving
            </h3>
            <span>
              Monday-Friday
              <br />
              07:00 a.m. - 03:30 p.m.
              <br />
              Saturday-Sunday
              <br />
              Closed
            </span>
          </div>
          <div className="  text-center md:text-left">
            <h3 className="mb-4 text-lg font-semibold sm:text-xl ">
              Follow Us
            </h3>
            <div className="flex ">
              <a
                href="https://www.instagram.com/abimouldings/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-3xl text-secondary-200 transition-all duration-300 hover:text-secondary-100 xl:text-4xl"
                aria-label="Visit our Instagram profile"
              >
                <FontAwesomeIcon icon={faSquareInstagram} className="" />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100087795861391"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-5 text-3xl  text-secondary-200 transition-all duration-300 hover:text-secondary-100  xl:text-4xl"
                aria-label="Visit our Facebook page"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                href="https://www.linkedin.com/company/abi-mouldings-ltd/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-3xl  text-secondary-200 transition-all duration-300 hover:text-secondary-100  xl:text-4xl"
                aria-label="Follow us in LinkedIn"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className=" bg-gray-100">
        <div className="container mx-auto">
          <div className="">
            <ul className="grid grid-cols-6 gap-2 px-6 py-2 text-sm text-main-100 lg:gap-0">
              <li className="col-span-6 justify-self-center lg:col-span-2 lg:justify-self-start">
                © Copyright {new Date().getFullYear()}, ABI Mouldings
              </li>
              <li className="col-span-6 justify-self-center underline lg:col-span-2">
                <NavLink
                  to="/privacy"
                  className="transition duration-300 hover:text-secondary-200 "
                >
                  Privacy Notice
                </NavLink>
                <NavLink
                  to="/terms"
                  className="mx-6 transition duration-300 hover:text-secondary-200 "
                >
                  Terms of Use
                </NavLink>
                <NavLink
                  to="/accesibility"
                  className="transition duration-300 hover:text-secondary-200 "
                >
                  Accesibility
                </NavLink>
              </li>
              <li className="col-span-6  justify-self-center lg:col-span-2 lg:justify-self-end">
                <button type="button" onClick={handleScroll}>
                  Go to top
                  <FontAwesomeIcon icon={faArrowUp} className="ml-2" />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
